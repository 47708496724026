import React from 'react'

const Guest = () => {
  return (
    <div>
        <h2>Welcome to simply lists!</h2>
        <p>Feel free to explore lists in the community or register/login to create your own!</p>
    </div>
  )
}

export default Guest